/* eslint-disable quote-props */
import * as angular from 'angular'

export const USE_TRANSACTIONAL_BENEFICIARY_ENDPOINT = true
export const BENEFICIARY_GA_EVENTS = {
  HO_SUCCESS: {
    event: 'HOME OFFICE BENEFICIARY CHANGE',
    action: 'SUCCESS',
    parameters: {},
  },
  HO_FAIL: {
    event: 'HOME OFFICE BENEFICIARY CHANGE',
    action: 'FAIL',
    parameters: {
      reason: '',
    },
    errorCode: 0,
  },
  CP_SUCCESS: {
    event: 'CLIENT BENEFICIARY CHANGE',
    action: 'SUCCESS',
    parameters: {},
  },
  CP_FAIL: {
    event: 'CLIENT BENEFICIARY CHANGE',
    action: 'FAIL',
    parameters: {
      reason: '',
    },
    errorCode: 0,
  },
}

const BENEFICIARY_EVENTS = {
  START_CHANGES: 'START_CHANGES',
  SAVE_CHANGES: 'SAVE_CHANGES',
  UNDO_CHANGES: 'UNDO_CHANGES',
  ENABLE_ADD_BENE_DD: 'ENABLE_ADD_BENE_DD',
  EDITOR_STATE_CHANGE: 'EDITOR_STATE_CHANGE',
  CHANGES_COMPLETED: 'CHANGES_COMPLETED',
}

const IGW_DISTRIBUTION_TYPECODES = {
  NONE: { tc: '', value: '' },
  EQUAL_SHARE: { tc: '1', value: 'Equal Share' },
  PERCENT_SHARE: { tc: '2', value: 'Percent' },
  FLATAMOUNT_SHARE: { tc: '5', value: 'Flat Amount' },
}

export const BENEFICIARIES_GENDERS = [
  { tc: '1', value: 'Male' },
  { tc: '2', value: 'Female' },
  // { tc: '3', value: 'Unisex' },
  { tc: '2147483647', value: 'Other' },
]

export const GENDER_MAP = {
  '1': BENEFICIARIES_GENDERS[0],
  '2': BENEFICIARIES_GENDERS[1],
  // '3': BENEFICIARIES_GENDERS[2],
  '2147483647': BENEFICIARIES_GENDERS[3],
}

export const BENEFICIARIES_DIST_TYPES = [
  { value: 'Per Capita', tc: '1' },
  { value: 'Per Stirpes', tc: '2' },
  { value: 'By Representation', tc: '1012300001' },
]

export const BENEFICIARIES_DIST_TYPES_WITH_TIPS = [
  { value: 'Per Capita', tc: '1', body: 'Proceeds will be payable to the beneficiary who survives the insured.' },
  { value: 'Per Stirpes', tc: '2', body: 'Proceeds will be payable to the beneficiary.  If the beneficiary dies before the Insured, the beneficiary\'s share of the proceeds will be paid to the beneficiary\'s descendants.' },
  { value: 'By Representation', tc: '1012300001', body: '(applies only to the children of the Insured): If the Insured\'s child dies before the Insured, the share of the child will be paid in equal shares to living children of the deceased child.If the Insured\'s child has no living children, that share will be paid in equal shares to the insured\'s surviving beneficiaries named in the same class.' },
]

export {IGW_DISTRIBUTION_TYPECODES, BENEFICIARY_EVENTS}

angular.module('app.beneficiaries', ['app.utils'])
  .constant('BENEFICIARY_EVENTS', BENEFICIARY_EVENTS)
  .constant('BENEFICIARY_SUFFIXES', ['I', 'II', 'III', 'IV', 'JR', 'SR'])
  .constant('BENEFICIARY_PROF_SUFFIXES', ['DDS', 'ESQ', 'MD', 'PHD'])
  .constant('BENEFICIARY_PREFIXES', ['COL', 'CPT', 'DR', 'MR', 'MRS', 'MS', 'REV'])
  .constant('BENEFICIARY_SETTLEMENT_OPTIONS',
  [
    'One Sum',
    'Interest Income Option',
    'Fixed Amount $',
    'Fixed Period - Yrs',
    'Life Income, No Death Ben',
    'Life With 5 Yrs Certain',
    'Life With 10 Yrs Certain',
    'Life With 20 Yrs Certain',
    'Life With Install. Refund',
  ])
  .constant('BENEFICIARIES_GENDERS', BENEFICIARIES_GENDERS)
  .constant('FINAL_BENEFICIARY_OPTIONS', [
    { key: '1', value: 'Executors or Administrators of the Estate of the {estateType}' },
    { key: '2', value: 'Executors or Administrators of the Estate of the Last Surviving Beneficiary' },
    { key: '3', value: 'Children of the insured, in equal shares' },
    { key: '4', value: 'Children born of the marriage of the insured and (name of spouse), in equal shares' },
    { key: '5', value: 'Grandchildren of the Insured' },
    { key: '6', value: 'Children of the Insured, in equal shares c/o (custodian name) custodian under the(state) UTMA' },
    { key: '7', value: 'Lawful Children of the Insured, in equal shares c/o (custodian name) custodian under the(state) UTMA' },
    { key: '8', value: 'Siblings of the Insured, in equal shares' },
    { key: '9', value: '(name of beneficiary) c/o (custodian name) as custodian under the(state) UTMA' },
  ])
  .constant('OWNER_ROLE_TYPE_CODES', [
    { value: 'Primary Owner', tc: '8' },
    { value: 'Joint Owner', tc: '184' },
    { value: 'Contingent Owner', tc: '177' },
  ])
  .constant('IGW_DISTRIBUTION_TYPECODES', IGW_DISTRIBUTION_TYPECODES)
  .constant('BENEFICIARIES_DIST_TYPES', BENEFICIARIES_DIST_TYPES)
