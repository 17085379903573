/* eslint-disable no-empty-function */

import { IGABaseDataLayer } from "../../activity-tracking/types"

/* eslint-disable no-underscore-dangle */
export class GoogleAnalyticsService {
  static $inject = ['$window', 'configService', 'authService']

  constructor(private $window: any, private configService: any, private authService: any) { }

  /**
   *
   * @param event: string | object
   * @param action?
   * @param properties?
   * @param errorCode?
   */
  send(event: string, action: string, properties?: any, errorCode?: string): void {
    const data: object = this._buildGtmObject(event, action, properties, errorCode)

    if (this.$window.dataLayer) {
      this.$window.dataLayer.push(data)
    } else {
      console.warn('dataLayer is missing')
    }
  }

  /**
 *
 * @param event: string | object
 * @param action?
 * @param properties?
 * @param errorCode?
 */
  send2(event: string, action: string, properties: any = {}): void {
    const data: any = this._buildGtmObject2(event, action, properties)

    if (this.$window.dataLayer) {
      this.$window.dataLayer.push(data)
    } else {
      console.warn('dataLayer is missing')
    }
  }

  /**
   *
   * @param event
   * @param action
   * @param properties
   * @param eCode
   */
  private _buildGtmObject(event: string, action: string, properties?: any, eCode?: any) {
    const hasParameters = typeof properties === 'object'
    const hasErrorCode = (hasParameters && typeof eCode !== 'undefined') || (!hasParameters && typeof properties !== 'undefined' && typeof eCode === 'undefined')
    const parameters = hasParameters ? properties : {}
    const errorCode = hasErrorCode && hasParameters ? eCode : hasErrorCode && !hasParameters ? properties : undefined
    const session = this.authService.getCurrentSession()
    const userId = session.loggedInAs
    const userType = this.authService.getUserType()
    const eventObject = Object.assign({
      event: event,
      action: action,
      user_id: userId,
      userType: userType,
      ...parameters,
    })

    if (hasErrorCode) eventObject.errorCode = errorCode

    return eventObject
  }

  /**
  *
  * @param event
  * @param action
  * @param properties
  * @param eCode
  */
  private _buildGtmObject2(event: string, action: string, properties: any = {}) {
    const environment: string = this.configService.environment
    const session = this.authService.getCurrentSession()
    const userId = session.loggedInAs
    const userType = this.authService.getUserType()
    const gaData: IGABaseDataLayer = {
      environment,
      event,
      action,
      user_id: userId,
      userType: userType,
    }
    const eventObject = Object.assign({}, gaData, { ...properties })


    console.log('_buildGtmObject2', eventObject)

    return eventObject
  }
}
