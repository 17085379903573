/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {StateService} from '@uirouter/core'
import { ClientAddressService } from './address-service'
import { AddressValidationResult, IAddress, IAddressPolicy, IAddressSuccessResult, IAddressValidationVO, IClientUpdateResponses, IClients, IPidGroup, IUsStates } from './types'

enum EditAddressState {
    VIEWING = 'VIEWING',
    CHANGING = 'CHANGING',
    COMPLETE = 'COMPLETE',
    CONFIRMING = 'CONFIRMING',
    CONFIRMATION = 'CONFIRMATION',
    NO_ADDRESSES = 'NO_ADDRESSES',
  }

enum LobTypes {
    LIFE = 'Life',
    ANNUITY = 'Annuity',
  }

enum PolicyTypes {
    POLICY = 'Policy',
    CONTRACT = 'Contract',
  }

export class ManageAddressesController {
  public addressesArray: IAddressPolicy[]
  public showEdit: boolean
  public state: EditAddressState
  public newAddress: IAddress
  public suggestedAddress: AddressValidationResult
  public submittedAddress: AddressValidationResult
  public requestPending: boolean = false
  public submittedSuccessfully: boolean = false
  public validationError: string
  public addressSelected: boolean
  public showCheckboxes: boolean
  public showMultipleAddressDescription: boolean
  public isMobile: boolean
  public selectedAddresses: IAddressPolicy[]
  public selectedRoles: any[] = []
  public usStates: IUsStates[]
  public modalOpen: boolean
  public roleIdArray: string[]
  public pidListTotal: number
  public successResult: IAddressSuccessResult
  public confirmationId: string
  public erroredResults: IClientUpdateResponses[]
  public erroredPids: string
  public allResultsHaveErrors: boolean
  public someResultsHaveErrors: boolean
  public noResultsHaveErrors: boolean
  public erroredPidLength: number
  public newAddressForm
  public addressFormLoading: boolean
  public headerDescriptionMessage: string
  public isHomeOffice: boolean
  public invalidOriginalAddressSelection: boolean
  public isAdvancedView: boolean = false
  public advancedViewButtonText: string = 'Advanced'
  public features: any
  public showAdvancedButton: boolean = false
  public selectedRolesHeader: string = ''
  public confirmationNumber: string = ''
    // public addressValidationResult: AddressValidationError

  constructor(addressesResult, private clientAddressService: ClientAddressService, private utils, private CONSTANTS,
      private policyUtils, private $state: StateService, private $element, private authService, private configService) {
      this.addressesArray = addressesResult.data?.addressPolicies || []
      this.addressesArray.map(address => {
        address.isSelected = false
        this.assignParamsToRoles(address)
      })
      this.showEdit = false
      this.state = this.addressesArray.length ? EditAddressState.VIEWING : EditAddressState.NO_ADDRESSES
      this.showCheckboxes = this.showCheckboxes = this.addressesArray.length > 1
      this.showMultipleAddressDescription = this.showCheckboxes
      this.isMobile = this.utils.isPhone()
      this.selectedAddresses = this.addressesArray.length > 1 ? [] : [this.addressesArray[0]]
      this.usStates = this.CONSTANTS.usStates
      this.modalOpen = false
      this.roleIdArray = []
      this.pidListTotal = 0
      this.submittedAddress = {
        attention: '',
        returnText: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        state: '',
        zip5: '',
        zip4: '',
        addressScrubInd: false,
      }
      this.$state = $state
      this.confirmationId = ''
      this.erroredResults = []
      this.erroredPids = ''
      this.allResultsHaveErrors = false
      this.someResultsHaveErrors = false
      this.noResultsHaveErrors = false
      this.erroredPidLength = 0
      this.newAddressForm = {}
      this.addressFormLoading = false
      this.isHomeOffice = this.authService.isInRole('HOMEOFFICE')
      this.headerDescriptionMessage = this._headerDescriptionMessage()
      this.invalidOriginalAddressSelection = true
      this.features = this.configService.features
    }

  $onInit() {
      this.showAdvancedButton = this._showAdvancedButton()
    }

  _showAdvancedButton() {
      return !this.authService.isARealClient() && this.features.enableAdvancedAddressView
    }

  confirmAddress() {
      this.newAddressForm = this.$element.find('form').scope().newAddressForm
      this.requestPending = true
      this.validate(this.newAddress)
      this.requestPending = false
      this.invalidOriginalAddressSelection = this.disableOriginalAddressSelection()
    }

  cancelAddressChange() {
      this.$state.go('^')
    }

  backToAddressSelection() {
      this.state = EditAddressState.VIEWING
    }

  applySuggestions(addressOptionIndicator: string) {
      this.setAddressOptionIndicator(addressOptionIndicator)
      this.state = EditAddressState.CONFIRMING
      this.close()
    }

  submitAddressChange(address: AddressValidationResult) {
      // Adding this so it is available for activity tracking.
      this.confirmationNumber = this.policyUtils.generateConfirmationNumber('AC', '2112', new Date())
      this.trackEvent('SUBMIT')
      return this.clientAddressService.saveAddress(this.roleIdArray, address).then((result) => {
        const resultArray: IClientUpdateResponses[] = result.data.clientUpdateResponses

        this.state = EditAddressState.CONFIRMATION
        this.allResultsHaveErrors = this.everyAddressResultHasError(resultArray)
        this.someResultsHaveErrors = this.addressResultHasErrors(resultArray)
        this.noResultsHaveErrors = this.addressResultHasNoErrors(resultArray)

        if (this.noResultsHaveErrors) {
          this.trackEvent('SUCCESS')
        }

        if (this.someResultsHaveErrors || this.allResultsHaveErrors) {
          this.trackEvent('FAIL')
        }

        if (this.noResultsHaveErrors || this.someResultsHaveErrors) {
          this.confirmationId = this.confirmationNumber // creating this before the call is made: this.policyUtils.generateConfirmationNumber('AC', '2112', new Date())
        }

        if (this.someResultsHaveErrors) {
          this.erroredResults = resultArray.filter(erroredResult => erroredResult.result.tc !== '1')
          this.erroredPidLength = this.erroredResults.length
          this.erroredPids = this.erroredResults.map(erroredResult => erroredResult.policyNumber).join(', ')
        }
      })
    }

  trackEvent(action: string) {
      if (window.dataLayer) {
        window.dataLayer.push(this.utils.buildGtmObject('CHANGE ADDRESS', action))
      }
    }

  addressResultHasNoErrors(results: IClientUpdateResponses[]): boolean {
      return results.every(result => result.result.tc === '1')
    }

  addressResultHasErrors(addressResponseArray: IClientUpdateResponses[]): boolean {
      const resultList = addressResponseArray.map(clientResult => clientResult.result.tc)

      return resultList.some(result => result !== '1') && resultList.some(result => result === '1')
    }

  everyAddressResultHasError(results: IClientUpdateResponses[]): boolean {
      return results.every(result => result.result.tc !== '1')
    }

  setAddressOptionIndicator(optionIndicator: string) {
      if (optionIndicator === 'suggestedAddress') {
        this.submittedAddress.addressScrubInd = true
        // copy over values
        // console.info(this.submittedAddress)
        // setTimeout(() => { // only using time out because getting $apply already in progress exception
        this.newAddress = Object.assign({}, this.submittedAddress)
          // this.newAddress = { ...this.submittedAddress}
        // }, 0)
      } else {
        this.submittedAddress.addressScrubInd = false
      }
    }

  backToEdit() {
      this.state = EditAddressState.CHANGING
    }

  validate(sourceAddress: IAddress): any {
      // copy address
      const sourcePayload: IAddressValidationVO = {...sourceAddress}

      this.addressFormLoading = true

      return this.clientAddressService.validate(sourcePayload).then(response => {
        const result: AddressValidationResult = response.data

        this.addressFormLoading = false

        this.validationError = ''

        if (sourceAddress.attention) {
          result.attention = sourceAddress.attention
        }

        this.suggestedAddress = result
        this.submittedAddress = this.suggestedAddress
        this.showEdit = true
        this.newAddressForm.line1.$invalid = false
        this.newAddressForm.city.$invalid = false
        this.newAddressForm.zip.$invalid = false
        this.newAddressForm.state.$invalid = false
        this.newAddressForm.line2.$invalid = false
        this.newAddressForm.line3.$invalid = false
        this.newAddressForm.zip4.$invalid = false
      }).catch(error => {
        this.state = EditAddressState.CHANGING
        this.addressFormLoading = false

        if (error.status === 500) {
          this.showEdit = true
        }

        if (error.data && error.status !== 500) {
          this.addressFormLoading = false
          this.validationError = 'We are unable to find the address as you have entered it. Please double check your entry and try again.'
          this.newAddressForm.line1.$invalid = true
          this.newAddressForm.city.$invalid = true
          this.newAddressForm.zip.$invalid = true
          this.newAddressForm.state.$invalid = true

          if (this.newAddressForm.line2.$viewValue) {
            this.newAddressForm.line2.$invalid = true
          }

          if (this.newAddressForm.line3.$viewValue) {
            this.newAddressForm.line3.$invalid = true
          }

          if (this.newAddressForm.zip4.$viewValue) {
            this.newAddressForm.zip4.$invalid = true
          }
        }
      })
    }

  changeAddress(): void {
      const options = !this.isAdvancedView ? this.selectedAddresses : this.selectedRoles

      this.state = EditAddressState.CHANGING
      this.roleIds(options)
      // this.policyUtils.pidReducer(this.selectedAddresses)
      this.pidListTotal = this.policyUtils.pidReducer(this.selectedAddresses).length
      this.selectedRolesHeader = this._selectedRolesHeader(this.selectedRoles)
    }

  policyTypeText(selectedAddresses: IAddressPolicy[], total: number): string {
      const lobCollection = selectedAddresses.map(addr => addr.policyClients)
        .reduce((acc, clients) => acc.concat(clients), [])
        .map(client => client.lineOfBusiness.value)

      if (lobCollection.includes(LobTypes.LIFE) && lobCollection.includes(LobTypes.ANNUITY)) {
        return 'policies and contracts'
      } else if (lobCollection.includes(LobTypes.LIFE) && !lobCollection.includes(LobTypes.ANNUITY)) {
        return total > 1 ? 'policies' : 'policy'
      }

      return total > 1 ? 'contracts' : 'contract'
    }

  isValidNewAddress(): boolean {
      if (this.newAddress && this.newAddress.line1 && this.newAddress.zip5) {
        return true
      }
      return false
    }

  disableOriginalAddressSelection(): boolean {
      return this.newAddress && (!this.newAddress.city || !this.newAddress.state)
    }

  close($event?: ng.IAngularEvent) {
      $event?.stopPropagation()
      this.showEdit = false
      console.count('close')
    }

  assignParamsToRoles(address: IAddressPolicy) {
      return address.policyClients.map((policy) => {
        return policy.partyRoles.map((partyRole) => {
          return partyRole.roles.map((role) => {
            role.pid = policy.pid
            role.isSelected = false
            role.partyName = partyRole.name
            role.lineOfBusiness = policy.lineOfBusiness
          })
        })
      })
    }

  _selectedRolesHeader(selectedRoles): string {
      const lobArray: string[] = []

      selectedRoles.forEach((role) => {
        lobArray.push(role.lineOfBusiness.value)
      })

      if (lobArray.includes('Life') && lobArray.includes('Annuity')) {
        return 'Policies/Contracts and Roles'
      } else if (lobArray.includes('Life') && !lobArray.includes('Annuity')) {
        return 'Policies and Roles'
      }

      return 'Contracts and Roles'
    }

  clientRoles(roleArray): any {
      const roles: string[] = []

      roleArray.forEach(role => {
        this.isAdvancedView ? roles.push(role) : roles.push(role.value)
      })

      if (this.isAdvancedView) {
        return roles
      }
      return roles.join(', ')
    }

  addressHasMultiplePolicies(address: IAddressPolicy): boolean {
      return address && address.policyClients && address.policyClients.length > 1
    }

  selectOption(option): void {
      const isAdvancedView = this.isAdvancedView
      let removeIndex

      if (option.isSelected) {
        !isAdvancedView ? this.selectedAddresses.push(option) : this.selectedRoles.push(option)
      } else {
        !isAdvancedView ? removeIndex = this.selectedAddresses.findIndex(item => !item.isSelected) :
        removeIndex = this.selectedRoles.findIndex(item => item.isSelected === false)

        if (removeIndex !== -1) {
          !isAdvancedView ? this.selectedAddresses.splice(removeIndex, 1) : this.selectedRoles.splice(removeIndex, 1)
        }
      }
    }

  pidList(selectedAddress: IAddressPolicy): IPidGroup {
      const pidTypes: IPidGroup = {
        policies: [],
        contracts: [],
      }

      selectedAddress.policyClients.forEach(policyClient => {
        if (policyClient.lineOfBusiness.value === LobTypes.LIFE) {
          pidTypes.policies.push(policyClient.pid)
        } else {
          pidTypes.contracts.push(policyClient.pid)
        }
      })

      return pidTypes
    }

  lineOfBusinessLabel(policy: IClients): string {
      if (policy.lineOfBusiness.value === LobTypes.ANNUITY) {
        return PolicyTypes.CONTRACT
      }

      return PolicyTypes.POLICY
    }

  roleIds(options): void {
      if (!this.isAdvancedView) {
        options.forEach(address => {
          address.policyClients.forEach(policyClient => {
            policyClient.partyRoles.forEach(partyRole => {
              partyRole.roles.forEach(role => {
                this.roleIdArray.push(role.clientPolicyRoleId)
              })
            })
          })
        })
      } else {
        options.forEach((role) => {
          this.roleIdArray.push(role.clientPolicyRoleId)
        })
      }
    }

  disableContinueButton() {
      if (this.isAdvancedView) {
        return !this.selectedRoles.length
      }
        return !this.selectedAddresses.length

    }

  _headerDescriptionMessage(): string {
      const clickOrTap = this.isMobile ? 'tap' : 'click'
      const policiesOrPolicy = this.addressHasMultiplePolicies(this.addressesArray[0]) ? 'policies' : 'policy'

      if (!this.isHomeOffice) {
        if (this.showMultipleAddressDescription) {
          return `Please use the check boxes to select the address or addresses you would like to change and ${clickOrTap} "Continue". The new address will be effective for all selected roles and policies. Please contact Client Services if you would like to update the address only for a particular role or policy. Client Services is available Monday through Friday, 8:30am to 6:00pm ET, at (800) 523-0650. Please call (855) 446-7393 if your policy was issued in New York.`
        }
        return `You are changing the address associated with the following ${policiesOrPolicy} and roles. Please contact Client Services if you would like to update the address only for a particular role or policy. Client Services is available Monday through Friday, 8:30am to 6:00pm ET, at (800) 523-0650. Please call (855) 446-7393 if your policy was issued in New York.`
      }

      if (this.showMultipleAddressDescription) {
        return 'Please use the check boxes to select the address or addresses you would like to change and click "Continue". The new address will be effective for all selected roles and policies. Please note: Updating the address only for a particular role or policy is not supported.'
      }

      return `You are changing the address associated with the following ${policiesOrPolicy} and roles. Please note: Updating the address only for a particular role or policy is not supported.`
    }

  toggleAdvancedView() {
      if (!this.isAdvancedView) {
        this.isAdvancedView = true
        this.advancedViewButtonText = 'Standard'
        this.selectedAddresses.forEach((address) => {
          address.isSelected = false
        })
        this.selectedAddresses = []
      } else {
        this.isAdvancedView = false
        this.advancedViewButtonText = 'Advanced'
        this.selectedRoles.forEach((role) => {
          role.isSelected = false
        })
        this.selectedRoles = []
      }
    }
}

ManageAddressesController.$inject = ['addressesResult', 'clientAddressService', 'utils', 'CONSTANTS', 'policyUtils', '$state', '$element', 'authService', 'configService']
