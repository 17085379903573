import angular from 'angular'
import { ActivityTrackingService } from './activity-tracking-service'
import { ActivityTraceDirective } from './activity-trace.directive'
import { ActivityTrackingDirective } from './activity-tracking.directive'
import { ActivityTrackingDatasourceDirective } from './activity-tracking-datasource.directive'

angular.module('app.logging')
  .service('activityTrackingService', ActivityTrackingService)
  .directive('activityTrace', ActivityTraceDirective.factory())
  .directive('activityTracking', ActivityTrackingDirective.factory())
  .directive('activityTrackingDatasource', ActivityTrackingDatasourceDirective.factory())
