import { IDirective, IDirectiveFactory } from "angular"
import { ActivityTrackingService } from "./activity-tracking-service"

/**
 * Tracks click events that pass event names and action names to Google Tag Mangager
 */
export class ActivityTraceDirective implements IDirective {

  restrict = 'A'
  replace = false

  constructor(private activityTrackingService: ActivityTrackingService) { }

  /**
   * Creates an instance of ActivityTraceDirective, with dependencies injected.
   */
  static factory(): IDirectiveFactory {

    const directive = (activityTrackingService: ActivityTrackingService) => new ActivityTraceDirective(activityTrackingService)

    directive.$inject = ['activityTrackingService']

    return directive
  }

  /**
   * AngularJS post link function use for initial configuration of instances of ActivityTraceDirective
   */
  link (_scope: any, el: any, attrs: any): void {
    el.on('click', () => {
      this.activityTrackingService.recordEvent(attrs.activityTrace)
    })
  }
}
