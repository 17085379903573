import { queryStringToObject } from '../utils/utils'

run.$inject = ['$rootScope', '$http', '$state', 'Idle', 'authService', 'deviceUtils', 'stateTransitions', 'navigationStack', 'CONSTANTS',
  '$location', 'mfaDataProvider', 'configService', '$urlService']

/* @ngInject */
// @Deprecated: This runner is now in auth-module-runner.ts
// @Note: The config service, and the authHttpResponseInterceptor are
export function run($rootScope, $http, $state, Idle, authService, deviceUtils, stateTransitions, navigationStack, CONSTANTS,
  $location, mfaDataProvider, configService, $urlService) {
  const sessionID = authService.getSessionID()

  $rootScope.auth = authService
  $rootScope.isClientView = authService.isClientView()
  $rootScope.isAgentView = authService.isAgentView()

  $rootScope.idleEvents = []

  if (sessionID) {
    $http.defaults.headers.common['Authorization'] = 'Bearer ' + sessionID

    // disable IE ajax request caching
    $http.defaults.headers.common['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT'
    // extra
    $http.defaults.headers.common['Cache-Control'] = 'no-cache'
    $http.defaults.headers.common['Pragma'] = 'no-cache'
  }

  // Client-side security. Server-side framework MUST add it's
  // own security as well since client-based security is easily hacked
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    const isAuthenticated = authService.isAuthenticated()
    const isNotAuthenticated = !isAuthenticated
    const isDeepLinkState = CONSTANTS.deepLinkStates.indexOf(toState.name) !== -1
    const isMfaVerified = mfaDataProvider.isMfaVerified()
    const isNotMfaVarified = !isMfaVerified

    console.info('*** Switching from %s, to state %s ', fromState.name, toState.name)

    // TODO: Delete this code block.  Does not seem necessary and breaks Insight filtering.
    // if (configService.features.mfaEnabled && isNotMfaVarified && fromState.name === toState.name) {
    //   console.log('Transitioning to same state')
    //   event.preventDefault ? event.preventDefault() : (event.returnValue = false)
    //   return
    // }

    // Handle deep link state
    if (toState.clientAppOnly && isNotAuthenticated && isDeepLinkState) {
      console.info('Detected allowed deep link attempt by unauthenticated user')
      event.preventDefault()
      window.location.href = CONSTANTS.authProxyURL + '/client?startUrl=' + window.encodeURIComponent($location.absUrl())
      return
    }

    // Not deep link state
    if (isAuthenticated) {
      // *** MFA Routing ***
      const isNotWhitelistedRoute = ['mfa.check', 'mfa.send-code', 'mfa.submit-code', 'login-client'].indexOf(toState.name) === -1
      const isNotInitialPayment = !toState.name?.startsWith('epay')

      console.log('authService.isARealClient(): %s, isNotWhitelistedRoute: %s, mfaEnabled: %s, isNotMfaVarified: %s, mfaVerified: %s, isNotInitialPayment: %s, finalTestResult: %s', authService.isARealClient(), isNotWhitelistedRoute, configService.features.mfaEnabled, isNotMfaVarified, isMfaVerified, isNotInitialPayment, toState.clientAppOnly && isNotWhitelistedRoute && configService.features.mfaEnabled && isNotMfaVarified && isNotInitialPayment)

      if (authService.isARealClient() && isNotWhitelistedRoute && configService.features.mfaEnabled && isNotMfaVarified && isNotInitialPayment) {
        // const returnUrl = window.encodeURIComponent(location.hash)
        const returnUrl = location.hash.substr(1)

        console.log('MFA Check required, redirecting to mfa.check. returnUrl: %s', returnUrl)
        event.preventDefault ? event.preventDefault() : (event.returnValue = false)

        $state.go('mfa.check', { 'redirect_url': returnUrl })
        // $urlService.url('/#/mfa/check?redirect_url=/' + returnUrl)
        return
      }

      console.log('MFA Check not required.')
      // *** Enable Idle service if not already running ***
      if (!Idle.running()) {
        Idle.watch()
      }

      // *** Part of search by SSN feature ***
      if ((toState.name.toLowerCase() === 'search.result' && toParams.c && angular.isNumber(parseInt(toParams.c))) &&
        (fromState.name && (fromState.name === 'policy'))) {
        event.preventDefault ? event.preventDefault() : (event.returnValue = false)

        stateTransitions.go('search.result', {
          t: 'result',
          c: '',
        })
      }
    } else if (toState && toState.data && toState.data.secure) { // *** isNotAuthenticated ***
      // *** Logout user if not authenticated and in a route that is marked as secure ***
      event.preventDefault()
      $rootScope.$evalAsync(function () {
        return authService.logout('client with toState info', toState)
      })
    }

    // Sets default footer unless toState matches a policy route, epay route or their children.
    if (/^(policy|epay)(\.)*/.test(toState.name)) {
      $rootScope.$broadcast('updateFooter')
    } else {
      $rootScope.carrierName = 'The Penn Mutual Life Insurance Company'
    }
  })

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    let toUrl, lastObj, lastUrl, state, params

    if (deviceUtils.isMobilePlatform()) {
      toUrl = $state.href(toState, toParams, {
        absolute: false,
      })

      lastObj = navigationStack.getLast()
      lastUrl = $state.href(lastObj.state, lastObj.params, {
        absolute: false,
      })

      if (lastUrl !== toUrl) {
        state = lastUrl === null ? toState : fromState
        params = lastUrl === null ? toParams : fromParams

        navigationStack.push(state, params)
      } else {
        navigationStack.pop()
      }
    }
  })
}

configure.$inject = ['$httpProvider', 'KeepaliveProvider', 'IdleProvider', 'CONSTANTS', 'TitleProvider']

/* @ngInject */
export function configure($httpProvider, KeepaliveProvider, IdleProvider, CONSTANTS, TitleProvider) {
  $httpProvider.interceptors.push('authHttpResponseInterceptor')
  IdleProvider.idle(CONSTANTS.IDLE)
  TitleProvider.enabled(true)
  IdleProvider.windowInterrupt('focus')
  IdleProvider.timeout(CONSTANTS.IDLE_TIMEOUT)
  KeepaliveProvider.interval(CONSTANTS.KEEP_ALIVE_INTERVAL)
  KeepaliveProvider.http(CONSTANTS.KEEP_ALIVE_URL)
}

authHttpResponseInterceptor.$inject = ['$rootScope', '$q', '$location', 'notificationCenterService', 'CONSTANTS', '$injector', '$state', 'MFA_ALLOWED_STATES']

/* @ngInject */
export function authHttpResponseInterceptor($rootScope, $q, $location, notificationCenterService, CONSTANTS, $injector, $state, MFA_ALLOWED_STATES) {


  return {
    request: function (config) {
      const activityTrackingService = $rootScope.activityTrackingService

      if (config.url !== '/nba-upload') config.timeout = CONSTANTS.globalRequestTimeout

      if (activityTrackingService.hasCurrentActivity && activityTrackingService.isTrackableURI(config.url)) {
        console.log('Trackable request URI: ', config)

        activityTrackingService.recordEvent('sent', false, config.data)
      }

      return config
    },
    response: function (response) {
      const activityTrackingService = $rootScope.activityTrackingService

      if (activityTrackingService.hasCurrentActivity && activityTrackingService.isTrackableURI(response.config.url)) {
        console.log('Trackable response URI: ', response)

        activityTrackingService.recordEvent('success', false, response)
      }

      // console.log(response)
      $rootScope.offline = false
      return response || $q.when(response)
    },
    responseError: function (rejection) {
      const activityTrackingService = $rootScope.activityTrackingService
      let authService

      if (activityTrackingService.hasCurrentActivity && activityTrackingService.isTrackableURI(rejection.config.url)) {
        console.log('Trackable responseError URI: ', rejection)

        activityTrackingService.recordEvent('failure', true, rejection)
      }

      window.dataLayer.push({
        event: 'response-error',
        location: $location.$$url,
        status: rejection.status,
        url: rejection.config.url,
      })

      if (rejection.status === 401 && !rejection.config.url.match(/^\/proxy\/core-services-gateway\/policy\/\d+\/canPay/)) {
        // When we get a 401 on the canPay endpoint, then we redirect to a special page.
        // We're OK with letting the epay routing handle that, and won't log out the user.
        rejection.statusText = 'Unauthorized, please try to Sign in again'
        authService = $injector.get('authService')
        authService.logout('Forced logout because 401 occured')
      } else if (rejection.status === 403) {
        const loggingService = $injector.get('loggingService')

        loggingService.log('Forced logout because 403/Forbidden access occured', 'error')
        rejection.statusText = 'Forbidden access'
        notificationCenterService.add('danger', rejection.statusText, CONSTANTS.notificationType.login, 0)
        authService = $injector.get('authService')
        console.error('Forced logout because 403 occured')

        authService.logout('Forced logout because 403 occured')
      } else if (rejection.status === 404) {
        rejection.statusText = "We couldn't find what you were looking for..."
      }  else if (!rejection.status || rejection.status === -1) {
        $rootScope.offline = true
        rejection.statusText = 'It appears that you lost your server connection.'
        notificationCenterService.add('danger', rejection.statusText, CONSTANTS.notificationType.login, 0)
      }

      const errorLog = {
        status: rejection.status,
        statusText: rejection.statusText,
        data: rejection.data
      }

      return $q.reject(rejection)
    },
  }
}
