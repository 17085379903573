import { IDirective, IDirectiveFactory } from "angular"
import { ActivityTrackingService } from "./activity-tracking-service"
import { StateService } from "angular-ui-router"

/**
 * Tracks click events that pass event names and action names to Google Tag Mangager
 */
export class ActivityTrackingDirective implements IDirective {
  restrict = 'A'
  replace = false

  constructor(private activityTrackingService: ActivityTrackingService, private $state: StateService) { }

  /**
   * Creates an instance of ActivityTraceDirective, with dependencies injected.
   */
  static factory(): IDirectiveFactory {

    const directive = (activityTrackingService: ActivityTrackingService, $state: StateService) => new ActivityTrackingDirective(activityTrackingService, $state)

    directive.$inject = ['activityTrackingService', '$state']

    return directive
  }

  /**
   * AngularJS post link function use for initial configuration of instances of ActivityTraceDirective
   */
  link(scope: any, _el: any, attrs: any): void {
    const stateName = this.$state.current.name
    const trackable = this.activityTrackingService.isTrackableActivity(stateName)
    const isInActivity = this.activityTrackingService.isInActivity(stateName)

    // Begin Activity Tracking Code
    if (trackable && !isInActivity) {
      const activity = this.activityTrackingService.registerActivity(stateName, attrs.activityTracking, scope)

      console.debug('ActivityTrackingDirective::link', stateName, trackable, isInActivity, activity)
    } else {
      console.debug('ActivityTrackingDirective::link', stateName, trackable, isInActivity, this.activityTrackingService.currentActivity)
    }
  }

}
