import * as angular from 'angular'
import {CountService, CountResult} from '../count-service'

const PRODUCTS = {
  Life: [{ name: 'Some Life Product' }],
  Annuity: [{ name: 'Some Annuity Product' }],
}

const HOLDSTATUS_INFORCE = 1
const RETURN_DOWNLOAD_URL = true

class Policy {
  polNumber: string
  value: number
  lineOfBusiness: string
  product: string
  anniversaryDate: Date
  rowHighlight: boolean = true
  insureds: any[]
  source: string
  holdingStatus: string
  policyStatus: string
  productType: string
  aceIndicator: boolean
  annuitants: any[]
  carrierCode: string
  carrierName: string
  effDate: string
  formalAppInd: boolean
  lastUpdateDate: string
  marketingName: string
  payouts: any[]
  primaryAgentCode: string[]
  primaryOfficeCode: string[]
  primaryServicingAgent: string[]
  requirementsTotal: number
  splitCase: boolean
  issueState: string
  advisorNameList: string[]
  hoReceiptDate: any
  submitDate
  deathBenefitAmt
  grossDeathBenefitAmt
  netDeathBenefitAmt
  type
  requirementInfo
  asOfDate
  requirementsStatus
  isAnnuity
  paymentAmountValue
  faceAmt
  initPaymentAmt
  paymentAmt
  payor
  beneficiary
  nyIssued
  advisorFullName
  insured
  owner
  isSuspended
  isRestricted
  restrictionCode
  jurisdiction
  productInitialized

  /**
   * ownerDisplay property
   */
  _ownerDisplay: string
  get ownerDisplay() { return this._ownerDisplay }
  set ownerDisplay(val) {
    if (Array.isArray(val) && val.length > 0) {
      this._ownerDisplay = val[0].fullName ?? 'n/a'
      // tslint:disable-next-line: strict-type-predicates
    } else if (typeof val === 'string') {
      this._ownerDisplay = val
    } else {
      this._ownerDisplay = 'n/a'
    }
  }

  /**
   * jointOwner
   */
  _jointOwner
  get jointOwner() { return this._jointOwner }
  set jointOwner(val) {
    this._jointOwner = val
    if (val) { // annuities don't have multiple owners, they have one plus an optional joint owner [WIT-48]
      this.owner.isMultiple = true
      this.owner.fullName = this.owner.fullName + ', +1'
    }
  }

  /**
   * insuredOrAnnuitant
   */
  // _insuredOrAnnuitant: string
  // get insuredOrAnnuitant() { return this._insuredOrAnnuitant }
  // set insuredOrAnnuitant(val) {
  //   if (Array.isArray(val) && val.length > 0) {
  //     this._insuredOrAnnuitant = val[0].fullName ?? 'n/a'
  //   } else {
  //     this._insuredOrAnnuitant = 'n/a'
  //   }
  // }

  _agumentRawPolicy() {
    // const enforceRestrictCodes = !this.authService.isInRole('HOMEOFFICE')

    this.isSuspended = this.holdingStatus === 'Suspended'
    this.isRestricted = this.authService.restrictByIssueState(this.issueState || this.jurisdiction)

    this.hoReceiptDate = this.dateUtils.parseDate(this.hoReceiptDate)
    this.submitDate = this.dateUtils.parseDate(this.submitDate)
    this.deathBenefitAmt = typeof this.grossDeathBenefitAmt === 'undefined' ? this.netDeathBenefitAmt : this.grossDeathBenefitAmt
    this.anniversaryDate = this.anniversaryDate || this.clientUtils.getAnniversaryDate(this.effDate)
    this.lastUpdateDate = this.lastUpdateDate ? this.dateUtils.parseDate(this.lastUpdateDate) : this.dateUtils.parseDate(this.asOfDate)
    this.type = this.CONSTANTS.policySource.pending
    this.requirementInfo = this.policyUtils.calcRequirementsMet(this.requirementsStatus)
    this.isAnnuity = this.lineOfBusiness && this.lineOfBusiness.toLowerCase() === this.CONSTANTS.annuityLineOfBusiness.toLowerCase()
    this.paymentAmountValue = this.policyUtils.getPaymentAmountValue(this.isAnnuity, this.faceAmt, this.initPaymentAmt, this.paymentAmt)
    this.policyStatus = this.utils.firstToUpper(this.policyStatus)
    this.owner = this.clientUtils.parsePartyDetails(this.owner)
    this.payor = this.clientUtils.parsePartyDetails(this.payor)
    this.beneficiary = this.clientUtils.parsePartyDetails(this.beneficiary)
    this.nyIssued = this.issueState === 'NY' || this.issueState === 'New York'

    this.advisorNameList = this.primaryServicingAgent

    if (this.advisorNameList) {
      this.advisorFullName = this.advisorNameList.length === 1 ? this.advisorNameList[0] : this.CONSTANTS.multipleAdvisorNameLabel
    }

    if (this.isAnnuity) {
      if (this.annuitants && this.annuitants.length > 0) {
        this.insured = this.clientUtils.parsePartyDetails(this.annuitants)
        this.insured.birthDate = this.dateUtils.parseDate(this.annuitants[0].birthDate)
      }
    } else if (this.insureds && this.insureds.length > 0) {
      this.insured = this.clientUtils.parsePartyDetails(this.insureds)
      this.insured.birthDate = this.dateUtils.parseDate(this.insureds[0].birthDate)
    }
  }

  _initializeWord(theWord) {
    const splitOnSpaces = theWord.split(' ')
    const isWhiteListed = splitOnSpaces.length > 1 && [''].includes(theWord)
    const finalResult = !isWhiteListed && splitOnSpaces.length > 1 ? splitOnSpaces.reduce((initialized, word) => {
      initialized += word[0].toUpperCase()
      return initialized
    }, '') : theWord

    return finalResult
  }

  /**
   *
   * @param rawDatum
   */
  constructor(datum, private dateUtils, private clientUtils, private CONSTANTS, private utils, private policyUtils, private authService) {
    // Transfer all of the raw properties received from back-end to this instance of Policy.
    Object.keys(datum).forEach((key: string) => {
      if (['jointOwner'].indexOf(key) === -1) {
        this[key] = datum[key]
      }
    })

    this._agumentRawPolicy()

    // Some special properties added for first cut of table view.
    this.value = datum.policyValue
    this.ownerDisplay = datum.owner
    // this.insuredOrAnnuitant = datum.insureds ?? datum.annuitants
    this.product = datum.productType
    this.productInitialized = this._initializeWord(datum.productType)
    this.jointOwner = datum.jointOwner
  }
}
class Policies {
  _policies: Policy[] = []

  add(rawDatum) {
    this._policies.push(new Policy(rawDatum, this.dateUtils, this.clientUtils, this.CONSTANTS, this.utils, this.policyUtils, this.authService))
  }

  get all() { return this._policies }

  constructor(private dateUtils, private clientUtils, private CONSTANTS, private utils, private policyUtils, private authService) { }
}

export class AllPoliciesDataProviderService {
  static $injector = ['$q', '$http', 'utils', 'partyService', 'CONSTANTS', 'allPoliciesService', 'dateUtils', 'clientUtils', 'policyUtils', 'authService', 'deviceUtils', 'FILTER_VIEW_STATES', 'countService']
  constructor(private $q: angular.IQService, private $http: angular.IHttpService, private utils, private partyService, private CONSTANTS, private allPoliciesService,
    private dateUtils, private clientUtils, private policyUtils, private authService, private deviceUtils, private FILTER_VIEW_STATES, private countService: CountService) { }

  /**
   *
   * @param options
   */
  _resolveEndPointUrl(options: any, returnDownloadUrl: boolean = false): string {
    const filterKey = options?.filterKey ?? this.partyService.getAgentKey()
    const source = this._resolveHoldingStatus(options.source)

    let url = `${this.CONSTANTS.apiRoot}agent/whatismybusiness${returnDownloadUrl ? '/download' : source.appParam + '?'}`

    url = this.utils.appendURLParameter(url, 'filterKey', filterKey)
    url = this.utils.appendURLParameter(url, 'holdingstatus', source.holdingStatusTypeCode)

    if (options.source === 'notpaid') {
      url = this.utils.appendURLParameter(url, 'lineOfBusiness', 1)
    }

    if (options?.sort?.direction) {
      url = this.utils.appendURLParameter(url, 'sort', options.sort.name)
      url = this.utils.appendURLParameter(url, 'order', options.sort.direction.toLowerCase())
    }

    if (returnDownloadUrl) {
      url = this.utils.appendURLParameter(url, 'rows', options.rows)
    } else {
      if (options?.page) {
        url = this.utils.appendURLParameter(url, 'page', options.page.page)
        url = this.utils.appendURLParameter(url, 'rows', options.page.size)
      }
    }

    if (options?.filter) {
      options.filter.forEach(filter => {
        switch (filter.field) {
          case 'includeState':
            const includeParts = filter.value.split(':')
            const field = includeParts[0]
            const value = includeParts[1]

            url = this.utils.appendURLParameter(url, field, value)
            break

          case 'formalAppIndicator':
            if (filter.value === 'Term Conversion') {
              url = this.utils.appendURLParameter(url, 'formalAppIndicator', true)
              url = this.utils.appendURLParameter(url, 'appType', 'Term Conversion')
            } else {
              url = this.utils.appendURLParameter(url, filter.field, filter.value)
            }
            break

          case 'appStatus':
            url = this.utils.appendURLParameter(url, 'status', filter.value)
            break

          default:
            url = this.utils.appendURLParameter(url, filter.field, filter.value)
        }
      })
    }

    return url
  }

  _resolveHoldingStatus(source) {
    return this.FILTER_VIEW_STATES[source]
  }

  /**
   * Returns the final paged result object
   *
   * @param data
   * @param tmpResult
   */
  _resolveFinalResult(policies: Policies, tmpResult) {
    const finalResult = { data: [] as Policy[], totalItems: 0, totalPages: 0 }

    finalResult.data = policies.all
    finalResult.totalItems = tmpResult.numFound
    finalResult.totalPages = Math.ceil(tmpResult.numFound / 10)

    return finalResult
  }

  /**
   * Plucks out the specifed property from the given raw result.
   *
   * @param rawResult
   * @param dataPropName
   */
  _extractResultsData(rawResult: any, dataPropName: string): any[] | any {

    const data = (rawResult.data || rawResult) || { [dataPropName]: [] }

    return data[dataPropName] || []
  }

  /**
   *
   */
  _mapResults(rawData: any[]): Policies {
    const finalResult: Policies = new Policies(this.dateUtils, this.clientUtils, this.CONSTANTS, this.utils, this.policyUtils, this.authService)

    rawData.forEach((rawDatum) => {
      finalResult.add(rawDatum)
    })

    return finalResult
  }

  /**
   *
   */
  _groupByOwnerName(policies, sort) {
    let currentOwner
    const isMobile = this.deviceUtils.isMobileFormfactor() || this.deviceUtils.isAppleDevice()

    if (!isMobile && sort.name === 'ownerfullname') {
      policies.all.forEach(policy => {
        // "Group" Duplicate fullNames

        if (currentOwner === policy.ownerDisplay) {
          policy.ownerDisplay = ''
          policy.rowHighlight = false
        } else {
          currentOwner = policy.ownerDisplay
          policy.rowHighlight = true
        }
      })
    }

    return policies
  }

  lineOfBusiness() {
    return this.$q.resolve([{ name: 'All', id: '', key: 'All' }, { name: 'Life Only', id: 1, key: 'Life' }, { name: 'Annuity Only', id: 2, key: 'Annuity' }])
  }

  appMethod() {
    return this.$q.resolve([{ name: 'All', id: '', key: 'All' }, { name: 'Electronic', id: 'true', key: 'Electronic' }, { name: 'Paper', id: 'false', key: 'Paper' }])
  }

  appTypes() {
    return this.$q.resolve([{ name: 'All', id: '', key: 'All' }, { name: 'Formal', id: 'true', key: 'Formal' }, { name: 'Informal', id: 'false', key: 'Informal' }, { name: 'Term Conversion', id: 'Term Conversion', key: 'Term Conversion'}])
  }

  deliveryStatus() {
    return this.$q.resolve([{ name: 'All', id: '', key: 'All'}, { name: 'Paper Delivery', id: '99', key: 'Paper Delivery'}, { name: 'Ready For Client Signature', id: '2', key: 'Ready For Client Signature'}, { name: 'Ready For Payment', id: '3', key: 'Ready For Payment'}, { name: 'Payment Complete', id: '4', key: 'Payment Complete'}, { name: 'E-Signature Expired', id: '22', key: 'E-Signature Expired'}])
  }

  appStatus() {
    let showAllStatusesOption = {name: 'All', id: '', key: 'All'}

    return this.countService.getUnsubmittedCount(false).then((result) => {
      return (result as CountResult[]).map((count) => {
        return {
          name: count.value,
          id: count.valueTypeCode.tc,
          key: count.value,
        }
      })
    }).then((mappedResult) => {
      mappedResult.unshift(showAllStatusesOption)

      return mappedResult
    })
  }

  daysOpenList() {
    return this.$q.resolve([{ name: 'All', id: '', key: 'All'}, { name: 'Last 30 Days', id: '30', key: 'Last 30 Days'}, { name: 'Last 60 Days', id: '60', key: 'Last 60 Days'}, { name: 'Last 90 Days', id: '90', key: 'Last 90 Days'}])
  }

  expiringList() {
    return this.$q.resolve([{ name: 'All', id: '', key: 'All'}, { name: 'In 7 Days', id: '7', key: 'In 7 Days'}, { name: 'In 14 Days', id: '14', key: 'In 14 Days'}, { name: 'In 21 Days', id: '21', key: 'In 21 Days'}])
  }

  products(productTypeParams: any | undefined) {
    if (productTypeParams) {
      const params: any = { source: productTypeParams.source, lineofbusiness: productTypeParams.lineOfBusiness.id }

      return this.allPoliciesService.getProductTypes(params)
        .then(result => {
          const mapd = result.productTypes.map(pt => {
            return { name: pt.valueTypeCode.value, id: pt.valueTypeCode.tc }
          }).sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })

          mapd.unshift({ name: 'All Product Types', id: '' })
          return mapd
        })
    } else {
      return this.$q.resolve([])
    }

  }

  pageSizes() {
    return this.$q.resolve([10, 20, 50, 100])
  }

  includeStates() {
    return this.$q.resolve([
      { name: 'All Issue States', id: '' },
      { name: 'NY State', id: 'includeState:New York' },
      { name: '49 States', id: 'excludeState:New York' },
    ])
  }

  policies(options) {
    const url = this._resolveEndPointUrl(options)
    let tmpResult: any = null

    return this.$http.get(url)
      .then((result: any) => tmpResult = result.data)
      .then(() => this._extractResultsData(tmpResult, 'policies'))
      .then(data => this._mapResults(data))
      .then(data => this._groupByOwnerName(data, options.sort))
      .then(data => this._resolveFinalResult(data, tmpResult))
  }

  _getDownloadConfig() {
    const ieFields = {
      fields: [
        { column: 'r8lf', header: 'Owner', field: 'OWNERFULLNAME' },
        { column: 'pid', header: 'Policy/Contract', field: 'POLNUMBER' },
        { column: 'pval', header: 'Value', field: 'POLICYVALUE' },
        { column: 'ntdbamt', header: 'DB', field: 'NETDEATHBENEFIT' },
        { column: 'rialf', header: 'Insured/Annuitant', field: 'INSUREDORANNUITANTFULLNAME' },
        { column: 'lob', header: 'Type', field: 'LINEOFBUSINESS' },
        { column: 'ptyppml', header: 'Product Type', field: 'PRODUCTTYPE' },
        { column: 'adt', header: 'Anniversary', field: 'ANNDATE' },
        { column: 'isdt', header: 'Issue Date', field: 'ISSUEDATE' },
        { column: 'mktnm', header: 'Product Name', field: 'PRODUCTNAME' },
        { column: 'famt', header: 'Face Amount', field: 'FACEAMT' },
        { column: 'acind', header: 'App Type', field: 'ACEINDICATOR' },
        { column: 'r38lf', header: 'Servicing Agent', field: 'PRIMARYSERVICINGAGENT' },
      ],
    }

    if (!this.utils.isIE()) {
      const nonIEfields = {
        fields: ieFields.fields.map((datum) => {
          return {
            column: datum.column,
            header: datum.header,
          }
        })}

      return nonIEfields
    }

    return ieFields
  }

  _downloadIE11(url, payload, fileName) {
    Object.keys(payload.fields).forEach((k) => {
      const field = payload.fields[k]

      url = this.utils.appendURLParameter(url, 'field', field.field)
    })

    url = this.utils.appendURLParameter(url, 'filename', fileName)
    url = this.utils.appendURLParameter(url, 'sid', this.authService.getSessionID())

    window.open(url, '_system')
  }

  _saveDownload(data, fileName) {
    const blob = new Blob([data], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')

    document.body.appendChild(a)
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    a.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      a.remove()
    }, 1)
  }

  _makeFileName() {
    const today = new Date()
    const dateParts = today.toDateString().split(' ').slice(1)

    return `Inforce-Book-${dateParts[1]}-${dateParts[0]}-${dateParts[2]}.csv`
  }

  download(options) {
    const url = this._resolveEndPointUrl(options, RETURN_DOWNLOAD_URL)
    const payload = this._getDownloadConfig()
    const fileName = this._makeFileName()

    if (this.utils.isIE()) {
      this._downloadIE11(url, payload, fileName)
      return this.$q.resolve()
    } else {
      return this.$http.post(url, payload)
        .then(r => {
          this._saveDownload(r.data, fileName)
        })
    }
    // .catch(err => console.log(err))
  }

}
