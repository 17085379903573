/* eslint-disable import/first */
// import $ from 'jquery'

import * as angular from 'angular'

import 'angular-ui-router'
import 'angular-ui-router/release/stateEvents'
import 'angular-sanitize'
import 'angular-animate'
import 'angular-cookies'
import 'angular-tooltips'
import 'ng-currency'
import 'angular-md5'
import 'angular-messages'
import 'ng-focus-if'
import 'angular-ui-bootstrap'
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls' // templates
import '@bower_components/ngInfiniteScroll/build/ng-infinite-scroll.min'
import 'ui-select'
import 'angular-ordinal'
import 'angular-ui-mask'

// import Sticky from '../scripts/sticky'

// stuff in index, not in modules

// import AppConfig from 'config/config-module'
import 'angular-loader'
import 'ng-idle'
import 'datejs'
// stuff not sure we actually need
// import 'c3-angular'

function getJSON(url, callback) {
  const xhr = new XMLHttpRequest()

  xhr.open('GET', url, true)
  xhr.responseType = 'json'
  xhr.onload = function () {
    if (xhr.status === 200) {
      callback(null, xhr.response)
    } else {
      callback(status)
    }
  }
  xhr.send()
}

function configureConfigServiceAndBootstrap(serverConfig) {
  function configure(configServiceProvider) {
    configServiceProvider.init(serverConfig)
  }

  configure.$inject = ['configServiceProvider']
  angular.module('app.config').config(configure)
  angular.bootstrap(document, ['app'])
}

angular.element(document).ready(function () {
  // @ts-expect-error
  if (window.isMobilePlatform) {
    configureConfigServiceAndBootstrap({serverMode: 'agent', defaultRoute: '/login'}) // override with default values if server call fails
  } else {
    getJSON(window.location.origin + '/config', function (error, response) {
      if (error || !response) {
        console.log('******* Failed to load configuration from server; using default')
        response = {serverMode: 'agent', defaultRoute: '/login'} // override with default values if server call fails
      }

      configureConfigServiceAndBootstrap(response)
    })
  }
})

import './common' // maybe this needs to go first
import './account'
import './alert'
import './auth'
import './batch-statements'
import './blocks'
import './cl-and-r'
import './client'  // move up
import './compensation'
import './components'
import './config'
import './contact'
import './core'
import './correspondence'
import './credits'
import './directives'
import './download-forms'
// import './me'  // @TODO make sure template file makes it to the build
import './mfa'
import './nba-upload'
// import './payment' // no code here
import './policy'
import './profile'
import './sales-achievement-reports'
import './replyable-requirements'

import './search'
import './terms-and-condition'

import './utils'
import './widgets'
import './user-settings'
import './transaction-history'
import './activity-tracking'
