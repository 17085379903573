import { IDirective, IDirectiveFactory } from "angular"
import { ActivityTrackingService } from "./activity-tracking-service"
import { StateService } from "angular-ui-router"

/**
 * Tracks click events that pass event names and action names to Google Tag Mangager
 */
export class ActivityTrackingDatasourceDirective implements IDirective {
  restrict = 'A'
  replace = false

  constructor(private activityTrackingService: ActivityTrackingService, private $state: StateService) { }

  /**
   * Creates an instance of ActivityTraceDirective, with dependencies injected.
   */
  static factory(): IDirectiveFactory {

    const directive = (activityTrackingService: ActivityTrackingService, $state: StateService) => new ActivityTrackingDatasourceDirective(activityTrackingService, $state)

    directive.$inject = ['activityTrackingService', '$state']

    return directive
  }

  /**
   * AngularJS post link function use for initial configuration of instances of ActivityTraceDirective
   */
  link(scope: any, _el: any, attrs: any): void {
    const stateName = this.$state.current.name

    if (this.activityTrackingService.isTrackableActivity(stateName) && this.activityTrackingService.isInActivity(stateName)) {
      this.activityTrackingService.setDatasource(attrs.activityTrackingDatasource, scope)
    }
  }

}
