import { IController, ISCEService } from 'angular'
import { CrafterService } from '../../utils/crafter-service'

class HtmlTextMessageController implements IController {
  $inject = ['crafterService', '$sce']

  constructor(private crafterService: CrafterService, private $sce: ISCEService) {}

  messageId: string
  message: string

  $onInit(): void {
  return this.crafterService.getHtmlText()
    .then((htmlText) => {
      this.message = this.$sce.trustAsHtml(htmlText[this.messageId]?.text)
    })
  }

}

export const htmlTextMessageComponent = {
  template: '<section ng-if="ctrl.message" ng-bind-html="ctrl.message"></section>',
  controller: HtmlTextMessageController,
  controllerAs: 'ctrl',
  bindings: {
    messageId: '<',
  },
  // require: {
  //   anotherCtrl: '^rolesComponent',
  // },
}
