import * as angular from 'angular'

function Browser() {
  const self = this

  self.MS_IE_VERSION_SUPPORTED = 8
  self.APP_IE_VERSION_SUPPORTED = 9
  self.FIREFOX_VERSION_SUPPORTED = 24
  self.CHROME_VERSION_SUPPORTED = 29
  self.SAFARI_VERSION_SUPPORTED = 5

  self.userAgent = navigator.userAgent.toLowerCase()

  self.FIREFOX = 'firefox'
  self.CHROME = 'chrome'
  self.SAFARI = 'safari'
  self.MOBILE = 'mobi'

  self.isMSIE = function() {
    return self.is('msie') || self.is('Trident')
  }

  this.isFirefox = function() {
    return self.is(self.FIREFOX)
  }

  this.isChrome = function() {
    return self.is(self.CHROME)
  }

  this.isSafari = function() {
    return self.is(self.SAFARI)
  }

  self.isPhone = function() {
    let check = false;

    (function(a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true
    })(navigator.userAgent || navigator.vendor || window.opera)

    return check
  }

  self.isMobileSafari = function() {
    const ua = window.navigator.userAgent
    const iOS = /iPad/i.test(ua) || /iPhone/i.test(ua)
    const webKit = /WebKit/i.test(ua)
    const IOSSafari = iOS && webKit && !/CriOS/i.test(ua)

    return IOSSafari
  }

  self.isMSIELessThan10 = function() {
    return self.is('msie')
  }

  self.is = function(t) {
    return RegExp(t, 'i').test(self.userAgent)
  }

  self.getMSIEVersion = function() {
    let rv = -1
    let re

    if (self.isMSIE()) {
      if (self.isMSIELessThan10()) {
        re = new RegExp('msie ([0-9]{1,}[.0-9]{0,})', 'i')

        if (re.exec(self.userAgent) != null) {
          rv = parseFloat(RegExp.$1)
        }
      } else {
        re = new RegExp('rv:([0-9]{1,}[.0-9]{0,})', 'i')

        if (re.exec(self.userAgent) != null) {
          rv = parseFloat(RegExp.$1)
        }
      }
    }

    return rv
  }

  self.getFirefoxVersion = function() {
    let rv = -1
    let re

    if (self.isFirefox()) {
      re = new RegExp('firefox/([0-9]+.[0-9]+(.[0-9]+)?)', 'i')

      if (re.exec(self.userAgent) != null) {
        rv = parseFloat(RegExp.$1)
      }
    }

    return rv
  }

  self.getChromeVersion = function() {
    let rv = -1
    let re

    if (self.isChrome()) {
      re = new RegExp('chrome/([0-9]+.[0-9]+.[0-9]+)', 'i')

      if (re.exec(self.userAgent) != null) {
        rv = parseFloat(RegExp.$1)
      }
    }

    return rv
  }

  self.getSafariVersion = function() {
    let rv = -1
    let re

    if (self.isSafari()) {
      re = new RegExp('version/([0-9]+.[0-9]+(.[0-9]+)?)', 'i')

      if (re.exec(self.userAgent) != null) {
        rv = parseFloat(RegExp.$1)
      }
    }

    return rv
  }
}

export function deepCompareObjects(current, original, blackList = [], usePrefixBlocking = false) {
  const startsWithRx = new RegExp('^(_|\\${2}|is).*', 'g')
  const diffs = {}
  let keys = current ? Object.keys(current) : null

  if(current?.whitelist?.length > 0) keys = keys?.concat(current.whitelist)

  if (!keys) return diffs

  keys.forEach((key) => {
    const blockedByPrefix = usePrefixBlocking && key.search(startsWithRx) > -1

    if (blockedByPrefix || blackList.find(tKey => tKey === key)) {
      return
    }

    const valIsDate = current[key] instanceof Date
    let valIsObject = typeof current[key] === 'object'
    let val1, val2

    if (valIsDate) {
      val1 = ""
      val2 = ""

      if (current[key]?.toJSON()) val1 = current[key]?.toISOString()
      if (original?.[key]?.toJSON()) val2 = original?.[key]?.toISOString()

      valIsObject = false // Date object has been coverted to a string
    } else {
      val1 = current[key]
      val2 = original?.[key]
    }


    if (!valIsObject && val1 !== val2) {
      diffs[key] = { before: val2, after: val1 }
     } else if (valIsObject) {
      diffs[key] = deepCompareObjects(val1, val2, blackList, usePrefixBlocking)

      if (Object.keys(diffs[key]).length === 0) {
        delete diffs[key]
      }
    }
  })

  return diffs
}

export function deepObjectCopy(original, blackList = []) {
  const deepCopy = {}
  const keys = original ? Object.keys(original) : null

  if (!keys) return deepCopy

  keys.forEach((key) => {
    if (blackList.find(tKey => tKey === key)) return

    const val1 = original?.[key]
    const valIsObject = typeof val1 === 'object'

    if (!valIsObject) {
      deepCopy[key] = val1
    } else if (valIsObject) {
      if (Array.isArray(val1)) {
        deepCopy[key] = val1.map((v) => typeof v === 'object' ? deepObjectCopy(v, blackList) : v)
      } else {
        deepCopy[key] = deepObjectCopy(val1, blackList)
      }
    }
  })

  return deepCopy
}

export function deepArrayOrObjectCopy(original, blackList = [], redact) {
  const isArray = Array.isArray(original)
  const deepCopy = isArray ? [] : {}

  if(isArray) {
    original.forEach((v, i) => {
      deepCopy[i] = typeof v === 'object' ? deepArrayOrObjectCopy(v, blackList, redact) : v
    })
  } else {
    const keys = original ? Object.keys(original) : null

    if (!keys) return deepCopy

    keys.forEach((key) => {
      const blackListedKey = Boolean(blackList.find(tKey => tKey === key))
      const val1 = blackListedKey && redact ? 'redacted' : original?.[key]
      const valIsObject = typeof val1 === 'object'

      if(blackListedKey && !redact) return

      if (!valIsObject) {
        deepCopy[key] = val1
      } else if (valIsObject) {
        if (Array.isArray(val1)) {
          deepCopy[key] = val1.map((v) => typeof v === 'object' ? deepArrayOrObjectCopy(v, blackList, redact) : v)
        } else {
          deepCopy[key] = deepArrayOrObjectCopy(val1, blackList, redact)
        }
      }
    })
  }

  return deepCopy
}

export function queryStringToObject(queryString) {
  // Test Pattern:  ?foo=bar&baz=1
  // Expected result:  {foo: 'bar', baz: 1}
  if (!queryString) {
    return {}
  }

  const tmp = queryString && queryString[0] === '?' ? queryString.slice(1) : queryString
  const parsed = tmp.split('&').reduce((params, param) => {
    const parts = param.split('=')

    params[parts[0]] = parts[1]
    return params
  }, {})

  return parsed
}
export {utils}

utils.$inject = ['$location', 'loggingService', 'md5', '$state', '$parse']

  /* @ngInject */
function utils($location, loggingService, md5, $state, $parse) {
  const service = {}

    // TODO: Move to a filter (or use the built-in Angular filter "uppercase")
  service.capitalizeString = function(capitalize) {
    if (capitalize) {
      return capitalize.toLowerCase().replace(/\b-?\w/g, function(m) {
        return m.toUpperCase()
      })
    }
  }

    /* eslint no-useless-escape:0 */
  service.isSsn = (ssnStr) => /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/.test(ssnStr)

  service.ssnMask = (ssnStr) => {
    if (!service.isSsn(ssnStr)) {
      return ssnStr
    }
    ssnStr = ssnStr.replace(/-/g, '')
    return '***-**-' + ssnStr.substr(ssnStr.length - 4, 4)
  }

  service.dedupe = function(array) {
      // Since we can't use a Set based on browser support, thanks to
      // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-an-array-remove-duplicates
      // Searching for unique or remove duplicates in our source code should also turn up this function,
      // hence this comment.
    if (Array.isArray(array)) {
      return array.filter((a, b) => array.indexOf(a) === b)
    }

    return array // we really want to return them the same garbage if its not an array?
  }

  service.dedupeByPropertyName = function(array, attr) {
    if (Array.isArray(array)) {
      return array.filter((obj, pos, arr) => {
        if ((obj[attr])) {
          return arr.map(mapObj => mapObj[attr]).indexOf(obj[attr]) === pos
        }
        return false // fall back if obj doesn't have the property.  Bad object anyway don't return it
      })
    }
    return array // we really want to return them the same garbage if its not an array?
  }

    // For use in map functions where we just want one property in an object.
    // In some languages you can just do a map supplying .propertyName as the function to use when mapping.
    // This function tries to approximate that.
  service.dot = function(propertyName) {
    return function(object) {
      return object[propertyName]
    }
  }

  service.isPromise = (obj) => {
    return Boolean(obj) && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
  }
    // For use in map functions where we need to pull values out of another object
    // based on property names in the array we're iterating through.
    // The opposite of service.dot.
  service.getFrom = function(sourceObject) {
    return function(propertyName) {
      return sourceObject[propertyName]
    }
  }

    /**
     * returns an AngularJs data 'Getter' function
     */
  service.createDataGetter = function(key) {
    return $parse(key)
  }

    /**
     * Returns the `value` from `source`, associated with the provided `key`.
     * Uses AngularJs `$parse` service to allow deep dotted notations for
     * deferencing the data.
     *
     * @param source: any
     * @param key: string
     *
     */
  service.getData = function(source, key) {
    const getter = $parse(key)

    return getter(source || {}) // return undefined for source is falsy
  }

    /**
     * Sets the `value` pn `source`, using the provided `key`.
     * Uses AngularJs `$parse` service to allow deep dotted notations for
     * deferencing the data.
     *
     * @param source: any
     * @param key: string
     * @param value: any
     */
  service.setData = function(source, key, value) {
    const setter = $parse(key).assign

    return setter(source, value)
  }

    /**
     * NOTE: implement this as an extension of `angular.element`
     *
     * Convert a JQuery style Object Array to a pure JavaScript array of elements.
     *
     * @param jqLite
     */
  service.jqLiteToArray = function(jqLite) {
    return Array.prototype.concat.apply([], jqLite)
  }

  service.jqLiteFindElementBySelector = function(selector) {
    const pureHtmlElement = document.querySelector(selector)
    const jqLiteElement = angular.element(pureHtmlElement)

    return jqLiteElement
  }

  service.getElementScope = function(jqe) {
    if (jqe && jqe.scope) return jqe.scope()

    return {}
  }

    /**
     * NOTE: implement this as an extension of `angular.element`
     *
     * Find a jQuery element that is adorned with the `targetAttr` specified.
     *
     * returns the first instance found as a jqLite obect containing a single element.
     *
     * @param jqLite
     */
  service.jqLiteFindByAttribute = function(jqLiteObj, targetAttr) {
    const arr = service.jqLiteToArray(jqLiteObj)

    const tmp = arr.filter(el => el.attributes[targetAttr] && el.attributes[targetAttr].specified)

    return tmp ? angular.element(tmp) : null
  }

    // TODO: Move to a filter
  service.firstToUpper = function(str) {
    let first, ending

    if (str) {
      first = str.substr(0, 1).toUpperCase()
      ending = str.substr(1).toLowerCase()

      return first + ending
    }
  }

    // Return a read-only copy of an object.
    // It will throw errors if you try to add or change stuff on it.
    // Does not take into account things on its prototype.
    // Meant for plain objects only.
  service.hermeticCopy = function(object) {
    const clone = {}

    Object.getOwnPropertyNames(object).forEach(function(key) {
      Object.defineProperty(clone, key, {
        configurable: false,
        enumerable: true,
        value: object[key],
        writable: false,
      })
    })
    Object.freeze(clone)

    return clone
  }

  service.isStringDigitsOnly = function(str) {
    return /^\d+$/.test(str)
  }

  service.isAlphaNumeric = (str) => /^\w+$/.test(str)

  service.appendURLParameter = function(url, param, value) {
    if (value === '' || value === null || value === undefined || !param) {
      return url
    }

    if (angular.isArray(value)) {
      if (value.length === 0) {
        return url
      }

      if (value.length === 1) {
        value = value[0]
      } else {
        value = value.join('&' + param + '=')
      }
    }

    if (url.indexOf('?') === -1) {
      return url + '?' + param + '=' + value
    }

      // It has a query, but no parameters yet
    if (url.indexOf('=') === -1) {
      return url + param + '=' + value
    }

    return url + '&' + param + '=' + value
  }

  service.getBaseURL = function() {
    let url = $location.$$protocol + '://' + $location.$$host
    const port = $location.port()

    if (port !== 80) {
      url = url + ':' + port
    }

    return url
  }

  service.objectValues = Object.values || function(object) {
    const values = []

    Object.keys(object).forEach(function(key) {
      if (object.hasOwnProperty(key)) {
        values.push(object[key])
      }
    })

    return values
  }

  service.createMd5Hash = function(str) {
    return md5.createHash(str)
  }

  service.minTwoDigits = function(n) {
    return ((n && n < 10) ? '0' : '') + n
  }

    /**
     * The workhorse; converts an object to x-www-form-urlencoded serialization.
     *
     * @param {Object} obj
     * @return {String}
     */
  service.getObjectAsFormParams = function(obj) {
    let query = ''
    let name
    let value

    for (name in obj) {
      value = obj[name]
      if (value !== undefined && value !== null) {
        query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&'
      }
    }

    return query.length ? query.substr(0, query.length - 1) : query
  }

  service.fillAndLogError = function(httpData, result) {
    result.error = httpData.statusText
    result.status = httpData.status
    result.statusText = httpData.statusText

      // logger.error('Error: ', httpData.statusText + ', ' + httpData.data + ', Url: ', httpData.config.url);
    // loggingService.log(httpData.statusText + ' Url: ' + httpData.config.url, 'error')
    return result
  }

  service.parseSortByOptions = function(option) { // expects field name preceeded by '+' or '-', but will default as if '+' were present if no direction specified
    let ascending = true
    const firstChar = option.charAt(0)
    let field

    if (firstChar !== '+' && firstChar !== '-') {
      field = option
    } else {
      field = option.substr(1)

      if (firstChar === '-') {
        ascending = false
      }
    }

    return new service.SortByOptions(field, (ascending ? 'asc' : 'desc'))
  }

  service.partition = function(predicate, inputArray) {
    const matches = []
    const nonMatches = []

    inputArray.forEach(function(entry) {
      if (predicate(entry)) {
        matches.push(entry)
      } else {
        nonMatches.push(entry)
      }
    })

    return [matches, nonMatches]
  }

  service.SortByOptions = function(field, order) {
    this.fieldName = field
    this.orderName = order
  }

  service.stopActions = function($event) {
    if ($event.stopPropagation) {
      $event.stopPropagation()
    }
    if ($event.preventDefault) {
      $event.preventDefault()
    }
    $event.cancelBubble = true
    $event.returnValue = false
  }

    // TODO: This seems like business logic and should not be in utils
  service.isNotAvailable = function(value) {
    return value === 'Undefined' || value === 'undefined' || value === undefined || value === 'Null' || value === 'null' || value === null || String(value).trim() === ''
  }

  service.createListColumns = function(arr, columnCount) {
    const itemsPerColumn = Math.ceil(arr.length / columnCount)
    const columns = []
    let i
    let col

    for (i = 0; i < arr.length; i += itemsPerColumn) {
      col = {start: i, end: Math.min(i + itemsPerColumn, arr.length)}
      columns.push(col)
    }

    return columns
  }

  service.printPopupWindow = function(element, windowTitle) {
    service.populatePrintPopupContent(element, windowTitle)
    service.closePrintPopupContent(windowTitle)
  }

  service.populatePrintPopupContent = function(element, windowTitle) {
    const printContents = document.getElementById(element).innerHTML
    const styleElements = document.querySelectorAll('link[rel=stylesheet]')
    const popup = window.open('', windowTitle, 'height=400,width=600, top=50, left=200 scrollbars=yes')
    let i

    popup.document.write('<html><head><title>' + windowTitle + '</title>')
    for (i = 0; i < styleElements.length; i++) {
      popup.document.write('<link rel="stylesheet" href="' + styleElements[i].href + '" />')
    }
    popup.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"/>')
    popup.document.write('</head><body>')
    popup.document.write('<div class="print-popup">' + printContents + '</div>')
    popup.document.write('</body></html>')
    popup.document.close()
  }

  service.sum = function(a, b) {
    return a + b
  }

  service.closePrintPopupContent = function(windowTitle) {
    const popup = window.open('', windowTitle, 'height=400,width=600, top=50, left=200 scrollbars=yes')

    popup.onload = function() {
      popup.focus()
      if (!service.isIE() && !service.isMobileSafari()) {
        popup.print()
        if (!service.isPhone()) {
          setTimeout(function() {
            popup.close()
          }, 100)
        }
      }
    }
  }

  service.isIE = function() {
    const browser = new Browser()

    return browser.isMSIE()
  }

  service.isPhone = function() {
    const browser = new Browser()

    return browser.isPhone()
  }

  service.isMobileSafari = function() {
    const browser = new Browser()

    return browser.isMobileSafari()
  }

  service.returnSortedList = function(sortedList, item) {
    const list = sortedList

    return list[item] || Object.keys(sortedList).length + 1
  }

  service.roundDecimal = function(number, decimalPlaces) {
    decimalPlaces = typeof decimalPlaces === 'number'
      ? decimalPlaces
      : 2

    return parseFloat(number.toFixed(decimalPlaces))
  }

  service.buildGtmObject = function(event, action, properties, errorCode) {
    let eventObject = {
      event: event,
      action: action,
    }

    if (properties) {
      eventObject = angular.merge(eventObject, properties)
    }

    if (errorCode) {
      eventObject.errorCode = errorCode
    }

    return eventObject
  }

  service.windowDataLayerPush = function(window, event, action, properties, errorCode) {
    const data = service.buildGtmObject(event, action, properties, errorCode)

    if (window.dataLayer) {
      window.dataLayer.push(data)
    } else {
      console.warn('dataLayer is missing')
    }
  }

  service.percentageOfTwoNumbers = function(numerator, denominator) {
    return Math.round((numerator / denominator) * 100)
  }

  service.goToRouteAndReload = function(route) {
    return $state.go(route, {}, {reload: true})
  }

  service.calculateRequirementsTotal = function(requirementsStatus) {
    const receivedObject = requirementsStatus.find(object => object.type === 'Received') || {count: 0}
    const completedObject = requirementsStatus.find(object => object.type === 'Completed') || {count: 0}

    return receivedObject.count + completedObject.count
  }

  service.queryStringToObject = queryStringToObject

  service.findClosestByTagName = function(selector, childElement) {
    const parentNode = childElement.parentNode

    if (!parentNode) return null

    if (parentNode?.tagName?.toLowerCase() === selector?.toLowerCase()) return parentNode

    return service.findClosestByTagName(selector, parentNode)
  }

  /**
   * Performs a deep compare of two object and returns an objec that show the differences.
   *
   * Only changed values are returned.
   *
   * @param {*} current
   * @param {*} original
   * @param {*} blackList
   * @returns
   */
  service.compareObjects = deepCompareObjects

  service.deepObjectCopy = deepObjectCopy

  service.deepArrayOrObjectCopy = deepArrayOrObjectCopy

  service.UID = function() {
    const chars = 'abcdef0123456789'
    let uniqueID = ''
    let i

    for (i = 0; i < 20; i++) {
      const start = Math.floor((Math.random() * 15))
      const end = start + 1

      uniqueID += chars.substring(start, end)
    }

    return uniqueID
  }

  return service
}
