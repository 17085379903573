import { IPromise } from 'angular'
import {CreditCardDataProviderService} from './credit-card-data-provider-service'
import { INITIAL_PAYMENT_CC_LIMIT } from '../../client/epay/epay-controller'

export class PaymentOptionButtonsController {
  $inject = ['modalService', 'creditCardDataProvider', '$sce', '$filter']

  isPennCheck: boolean
  acceptedPaymentMethods: any
  showCardPaymentButton: boolean
  showAddNewCreditCardButton: boolean
  onSave: Function
  showCreditCardLimitMessage: boolean
  initialPaymentAmount: number
  totalPaymentAmount: number
  disableCardPaymentButton: boolean
  invalidPaymentForm: boolean
  creditCardButtonText: string
  achButtonText: string
  disableACHButton: boolean
  creditCardLimit: string

  constructor(private modalService: any, private creditCardDataProvider: CreditCardDataProviderService, private $filter: any) { }

  initCCForm(): IPromise<any> {
    return this.creditCardDataProvider.getPaymentusCreditCardUrl().then((sessionTransferUrl) => {
      return sessionTransferUrl
    }).catch(err => console.error(JSON.stringify(err, null, 2)))
  }

  openCCForm() {
    this.modalService.openComponent('creditCardForm', {
      windowClass: 'credit-card-form',
      size: 'lg',
      resolve: {
        deepLinkUrl: () => this.initCCForm(),
      },
    })

    return this.modalService.modalInstance.result
      .then((bankInfo) => this.creditCardDataProvider.sendCreditCardType(bankInfo))
      .then((bankInfo) => this.onSave({ bankInfo, source: 'ccModal', saveAs: 'initialPaymentAccount', continueAfterSave: !this.isPennCheck }))
      .catch((error) => {
        console.log(error)
      })
  }

  openBankForm() {
    const defaultBankingInfo = {
      accountType: {
        tc: 'C',
        value: 'Checking',
      },
      saveAccountInfo: false,
    }

    this.modalService.openComponent('bankingInfoModal', {
      windowClass: 'bank-payment-form',
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        buttonLabel: () => this.isPennCheck ? 'Save' : 'Save and Continue',
        bankingInfo: () => defaultBankingInfo,
      },
    })

    return this.modalService.modalInstance.result.then((bankInfo) => this.onSave({ bankInfo, source: 'bankingInfoModal', saveAs: 'initialPaymentAccount', continueAfterSave: !this.isPennCheck }))
  }

  $onInit() {
    this.showCardPaymentButton = this.showAddNewCreditCardButton
    this.creditCardLimit = this.$filter('currency')(INITIAL_PAYMENT_CC_LIMIT)
    // this.showCreditCardLimitMessage = false
  }

  $onChanges(_changes) {
    this.disableCardPaymentButton = this.showAddNewCreditCardButton && (this.invalidPaymentForm || this.totalPaymentAmount > INITIAL_PAYMENT_CC_LIMIT)
    this.showCreditCardLimitMessage = this.showAddNewCreditCardButton && this.totalPaymentAmount > INITIAL_PAYMENT_CC_LIMIT
    this.disableACHButton = this.invalidPaymentForm
  }
}