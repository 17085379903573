import * as angular from 'angular'

import './app-switcher'
import { AppSwitcherService } from './app-switcher/app-switcher-service'
import { AppSwitcherController } from './app-switcher/app-switcher.component'
import { CommonNavController } from './common-nav/common-nav.component'
import { ADDRESS_FRAGMENTS, ADDRESS_MESSAGE_MAP, BUSINESS_UNITS, MESSAGES } from './companies/address-constants'
import { CompaniesService } from './companies/companies-service'
import { FORMS_MAJOR_SECTION_NAME } from './companies/forms-constants'
import { CompanyForms } from './companies/forms.component'
import './google-analytics'
import { ModalInstanceCtrl } from './modal/modal-instance-controller'
import { ModalService } from './modal/modal-service'
import './payments'
import { BankInfoForm } from './payments/bank-info-form.component'
import { BankingInfoModal } from './payments/banking-info-modal.component'
import { PhoneNumberInput } from './phone-number-input/phone-number-input-component'
import { PolicyTaxDocuments } from './policy-tax-documents/policy-tax-documents.component'
import { ProfileDropdown } from './profile-dropdown/profile-dropdown.component'
import { SliderSwitch } from './slider-switch/slider-switch.component'
import { IdleMonitorController } from './idle-monitor/idle-monitor.component'
import { ComboBoxComponent } from './combo-box/combo-box.component'
import { infoHeadingBlockComponent } from './info-heading-block/info-heading-block.component'
import { clientCardComponent } from './client-card/client-card.component'
import { phoneListComponent } from './phone-list/phone-list-component'
import { dropdownComponent } from './dropdown/dropdown.component'
import { CheckBoxComponent } from './check-box/check-box.component'
import { shareInputComponent } from './share-input/share-input.component'
import { AddressController } from '../client/beneficiaries/address'
import { AddressValidationComponentConfig } from './address-validation/address-validation.component'
import { partyPersonNameComponent } from './party-person-name/party-person-name.component'
import { PolicyForms } from './policy-forms/policy-forms.component'
import { ClientForms } from './client-forms/client-forms.component'
import { FormFilterService } from './companies/form-filter-service'
import { GaTrackerDirective } from './google-analytics'
import { htmlTextMessageComponent } from './html-text-message/html-text-message.component'

angular.module('app.components', [])
  .service('modalService', ModalService)
  .controller('modalInstanceCtrl', ModalInstanceCtrl)
  .service('companiesService', CompaniesService)
  .service('appSwitcherService', AppSwitcherService)
  .service('formFilterService', FormFilterService)
  .constant('ADDRESS_FRAGMENTS', ADDRESS_FRAGMENTS)
  .constant('ADDRESS_MESSAGE_MAP', ADDRESS_MESSAGE_MAP)
  .constant('BUSINESS_UNITS', BUSINESS_UNITS)
  .constant('MESSAGES', MESSAGES)
  .constant('FORMS_MAJOR_SECTION_NAME', FORMS_MAJOR_SECTION_NAME)
  .component('companyForms', CompanyForms)
  .component('bankInfoForm', BankInfoForm)
  .component('bankingInfoModal', BankingInfoModal)
  .component('checkBox', CheckBoxComponent)
  .component('comboBox', ComboBoxComponent)
  .component('dropdown', dropdownComponent)
  .component('infoHeadingBlock', infoHeadingBlockComponent)
  .component('clientCard', clientCardComponent)
  .component('htmlTextMessage', htmlTextMessageComponent)
  .component('appSwitcher', {
    templateUrl: 'app/components/app-switcher/app-switcher.html',
    controller: AppSwitcherController,
    controllerAs: 'ctrl',
    bindings: {
    },
  })
  .component('commonNav', {
    templateUrl: 'app/components/common-nav/common-nav.html',
    controller: CommonNavController,
    controllerAs: 'ctrl',
    bindings: {

    },
  })
  .component('idleMonitor', {
    templateUrl: 'app/components/idle-monitor/idle-monitor.html',
    controller: IdleMonitorController,
    controllerAs: 'idleDebugCtrl',
    bindings: {
      sessionDebugOn: '=',
    },
  })
  .component('phoneNumberInput', PhoneNumberInput)
  .component('policyTaxDocuments', PolicyTaxDocuments)
  .component('profileDropdown', ProfileDropdown)
  .component('sliderSwitch', SliderSwitch)
  .component('phoneList', phoneListComponent)
  .component('shareInput', shareInputComponent)
  .component('addressValidation', AddressValidationComponentConfig)
  .component('partyPersonName', partyPersonNameComponent)
  .component('policyForms', PolicyForms)
  .component('clientForms', ClientForms)
  .directive('gaTracker', GaTrackerDirective.factory())

